import {
  useAppDispatch,
  useAppSelector,
} from "../../../../utility/redux/state/hooks";
import { Button } from "../../shared/button";
import { DropDown } from "../../shared/drop-down";
import "./styles.scss";
import { IoCarSportSharp } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";
import { TextBox } from "../../shared/TextBox";
import { useForm } from "react-hook-form";
import { FC, useEffect, useState } from "react";

import { AddCircle, FilterAlt, FilterAltOff } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { setIsSellRequestModalOpen } from "../../../../utility/redux/state/features/sell-requests.slicer";
import {
  useGetQueryQuery,
  useLazyGetQueryQuery,
} from "../../../../utility/redux/api/api-slice";
import { ENDPOINTS } from "../../../../utility/redux/state/features/actions/endpoints";
import { ShopSearchT } from "../NewSellRequestModal/types";
import { AutoCompleteFormHook } from "../../shared/AutoCompleteFormHook";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";

export const managementType = [
  { name: "METACAR certified & Managed", value: "certified-managed" },
  { name: "METACAR certified & Self Managed", value: "certified-only" },
  { name: "Uncertified & Self Managed", value: "uncertified" },
];

interface Props {
  onChangeFilter: (payload: any) => void;
  buyReq?: boolean;
  countBuyReq?: number;
  sellReq?: boolean;
}
export const RequestFilter: FC<Props> = ({
  onChangeFilter,
  buyReq,
  countBuyReq,
  sellReq,
}) => {
  const dispatch = useAppDispatch();

  const { data: states } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}/locations/Malaysia`
  );
  const [selectedBrand, setSelectedBrand] = useState("");
  const [currentFilter, persistFilter] = useState<any>();

  const [searchShopBy, setSearchShopBy] = useState<any>("pic");
  const [selectedShop, setIsSelectedShop] = useState<string>("");
  const [shopId, setShopId] = useState<string>("");

  const [trigger, data] = useLazyGetQueryQuery();
  const shopSearchResult: ShopSearchT = data.data;

  const count = useAppSelector((state) => state.sellRequestReducer.count);
  const availableOptions = useAppSelector(
    (state) => state.sellRequestReducer.availableOptions
  );

  const currentFilterQueyParams = useAppSelector(
    (state) => state.sellRequestReducer.queryParams
  );
  const { data: basicData } = useGetQueryQuery(`${ENDPOINTS.BASIC_ITEMS}`);

  const { register, handleSubmit, watch, control, setValue, reset, getValues } =
    useForm({
      defaultValues: {
        brand: "",
        status: "",
        model: "",
        registration_no: "",
        start_date: "",
        end_date: "",
        client: "",
        tracking_code: "",
        management: "",
        phone: "",
        email: "",
        tag: "",
        id: "",
        grade: "",
        Models: "",
        state_id: "",
      },
    });

  const brand = watch("brand");

  const { data: carModelsConditional } = useGetQueryQuery(
    `${ENDPOINTS.BASIC_ITEMS}/search/models?parent=${brand}`,
    { skip: !brand }
  );

  useEffect(() => {
    const currentShop = selectedShop.split(" ");
    setShopId(currentShop[0]);
  }, [selectedShop]);

  useEffect(() => {
    setTimeout(() => {
      reset({
        id: currentFilterQueyParams?.id,
        grade: currentFilterQueyParams?.grade,
        status: currentFilterQueyParams?.status,
        model: currentFilterQueyParams?.model,
        brand: currentFilterQueyParams?.brand,
        registration_no: currentFilterQueyParams?.registration_no,
        start_date: currentFilterQueyParams?.start_date,
        end_date: currentFilterQueyParams?.end_date,
        client: currentFilterQueyParams?.client,
        tracking_code: currentFilterQueyParams?.tracking_code,
        management: currentFilterQueyParams?.management,
        phone: currentFilterQueyParams?.phone,
        email: currentFilterQueyParams?.email,
        tag: currentFilterQueyParams?.tag,
      });
    }, 100);
  }, [reset, currentFilterQueyParams]);

  useEffect(() => {
    if (selectedBrand && selectedBrand !== brand) {
      setValue("Models", "");
    }
  }, [selectedBrand]);

  const onSubmitHandle = (data: any) => {
    reset();
    setSelectedBrand("");
    if (data.state_id) {
      data.state_id = data.state_id.id;
    }

    if (shopId) {
      data.shop_id = shopId;
    }
    persistFilter(data);
    onChangeFilter(data);
    if (Object.keys(data).length === 0) {
      reset({
        registration_no: "",
        start_date: "",
        end_date: "",
        client: "",
        tracking_code: "",
        management: "",
        phone: "",
        email: "",

        id: "",
      });
    }
  };

  const setSellRequestModal = () => {
    dispatch(setIsSellRequestModalOpen(true));
  };

  const onSearchInputHandler = (text: string) => {
    if (text.length && text.length > 1) {
      const queryParams = `${searchShopBy}=${text}`;
      trigger(`${REST_STAFF_ENDPOINTS.shop}?${queryParams}`);
    }
  };
  return (
    <Box
      className="request-filter-container"
      style={{ backgroundColor: "#FAF9F6" }}
    >

      -{JSON.stringify(brand)}-
      <form onSubmit={handleSubmit(onSubmitHandle)}>
        <Box display={"flex"} flexDirection={"column"} gap={4}>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Box display={"flex"} gap={2} flexWrap={"wrap"}>
              <Box>
                <DropDown
                  name="status"
                  control={control}
                  defaultValue={currentFilter?.status}
                  items={
                    availableOptions?.statuses?.map((st) => ({
                      id: st,
                      title: st,
                    })) ?? []
                  }
                  label="Status"
                  register={{ ...register("status") }}
                />
              </Box>
              <Box>
                <DropDown
                  name="brand"
                  control={control}
                  items={basicData?.brands ?? []}
                  label="Brand"
                  register={{ ...register("brand") }}
                  onChangeHandler={(event: any) => {
                    return setSelectedBrand(event.target.value);
                  }}
                />
              </Box>
              <Box>
                <DropDown
                  name="model"
                  control={control}
                  defaultValue={currentFilter?.model}
                  items={carModelsConditional?.models ?? []}
                  label="Models"
                  register={{ ...register("model") }}
                />
              </Box>
              <Box>
                <DropDown
                  name="management"
                  control={control}
                  defaultValue={currentFilter?.status}
                  basedInId
                  items={
                    managementType?.map((st) => ({
                      id: st.value,
                      title: st.name,
                    })) ?? []
                  }
                  label="Management"
                  register={{ ...register("management") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  onKeyPress={(data: any) => {
                    if (data.charCode === 13) {
                      handleSubmit(onSubmitHandle);
                    }
                  }}
                  label="Registration No"
                  register={{ ...register("registration_no") }}
                // value={
                //   getValues("registration_no")
                //     ? getValues("registration_no")
                //     : undefined
                // }
                />
              </Box>
              <Box>
                <TextBox
                  label="Start Date"
                  type="date"
                  register={{ ...register("start_date") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  label="End Date"
                  type="date"
                  register={{ ...register("end_date") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  label="Client"
                  register={{ ...register("client") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  label="Tracking Code"
                  register={{ ...register("tracking_code") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  label="Car Id"
                  register={{ ...register("id") }}
                />
              </Box>
              <Box>
                <DropDown
                  name="grade"
                  control={control}
                  defaultValue={currentFilter?.status}
                  basedInId
                  items={
                    [
                      { id: 1, title: "1" },
                      { id: 2, title: "2" },
                      { id: 3, title: "3" },
                      { id: 4, title: "4" },
                      { id: 5, title: "5" },
                    ]?.map((st) => ({
                      id: st.id,
                      title: st.title,
                    })) ?? []
                  }
                  label="Grade"
                  register={{ ...register("grade") }}
                />
              </Box>

              <Box sx={{ minWidth: 160 }}>
                <AutoCompleteFormHook
                  disabled={!states?.list.length}
                  options={states?.list}
                  name="state_id"
                  label="state"
                  control={control}
                  defaultValue={""}
                />
              </Box>

              <Box sx={{ minWidth: 160 }}>
                <DropDown
                  name="tag"
                  control={control}
                  defaultValue={currentFilter?.tag}
                  disabled={sellReq ? false : true}
                  basedInId
                  items={
                    [
                      { id: "good-price", title: "Good Price" },
                      { id: "best-deal", title: "Best Deal" },
                    ]
                  }
                  label="Deal"
                  register={{ ...register("tag") }}
                />
              </Box>

              <Box>
                <TextBox
                  disabled={sellReq ? false : true}
                  width={160}
                  label="Phone Number"
                  register={{ ...register("phone") }}
                />
              </Box>
              <Box>
                <TextBox
                  disabled={sellReq ? false : true}
                  width={260}
                  fullWidth
                  label="Email"
                  register={{ ...register("email") }}
                />
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  backgroundColor: "rgba(195, 255, 104,0.1)",
                  width: 460,
                }}
              >
                <Box sx={{ minWidth: 160 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Search Shop By
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={searchShopBy}
                      label="Search Shop By"
                      onChange={(item) => setSearchShopBy(item.target.value)}
                    >
                      <MenuItem value={"pic"}>By PIC</MenuItem>
                      <MenuItem value={"email"}>By Email</MenuItem>
                      <MenuItem value={"name"}>By Name</MenuItem>
                    </Select>
                  </FormControl>
                </Box>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={
                    shopSearchResult &&
                      shopSearchResult.shops &&
                      shopSearchResult.shops.length
                      ? shopSearchResult.shops.map((option) => {
                        return `${option.id} ,${option.name} ,Managed By ${option.pic.name} `;
                      })
                      : []
                  }
                  sx={{ width: 300 }}
                  onChange={(_event, value) => {
                    value && setIsSelectedShop(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Shop"
                      onChange={(event) =>
                        onSearchInputHandler(event.target.value)
                      }
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Button color="success" label="Apply" icon={<FaFilter />} />
                <Button
                  color="success"
                  type="button"
                  onClick={() => {
                    onSubmitHandle({});
                  }}
                  label="Clear Filters"
                  icon={<FilterAltOff />}
                />
              </Box>

              {!buyReq ? (
                <Button
                  type="button"
                  color="success"
                  variant="contained"
                  onClick={() => {
                    setSellRequestModal();
                  }}
                  label="Create Sell Request"
                  icon={<AddCircle />}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
      </form>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          mt: 2,
        }}
      >
        <span>
          {" "}
          Available Cars:{" "}
          <b style={{ color: "green" }}> {buyReq ? countBuyReq : count} </b>
          <IoCarSportSharp />
        </span>
      </Box>
    </Box>
  );
};
