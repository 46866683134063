import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Container,
  Dialog,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";

import { ShopRequestT, ShopSearchT, selRequestSubmitT } from "./types";
import { useDispatch } from "react-redux";
import {
  useGetQueryQuery,
  useLazyGetQueryQuery,
  usePostRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import { NumericFormatCustom } from "../../../../utility/helpers/NumericFormat";
import { AutoCompleteFormHook } from "../../shared/AutoCompleteFormHook";
import { SelectFormHook } from "../../shared/SelectFormHook";
import { setIsSellRequestModalOpen } from "../../../../utility/redux/state/features/sell-requests.slicer";
import { useAppSelector } from "../../../../utility/redux/state/hooks";
import { RULES } from "./rules";
import { useNavigate } from "react-router-dom";
import { ReactNode, useEffect, useState } from "react";
import { ShopModal } from "./ShopModal";
import { DropDown } from "../../shared/drop-down";
import { ShopDetail } from "./ShopDetal";
import Loading from "../../General/loading/loading";

interface Inputs {
  price: string;
  registrationNo: string;
  bodyId: string;
  colorId: string;
  gearId: string;
  variantId: { id: number; title: string };
  modelId: { id: number; title: string };
  brands: { id: number; title: string };
  year: string;
  month: string;
  type: string;
  state: { id: number; title: string };
  city: string;
  country: { id: number; title: string };
  postalCode: string;
  addressLine: string;
  name: string;
  email: string;
  phoneNumber: string;
}

const buildYears = () => {
  const now = new Date().getUTCFullYear();
  return Array(now - (now - 50))
    .fill("")
    .map((v, idx) => now - idx) as Array<number>;
};
export const basicYears = buildYears().map((year, idx) => {
  return { id: year.toString(), title: year.toString() };
});

export const NewSellRequestModal = () => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isShopModalOpen, setIsShopModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const isSellRequestModalOpen = useAppSelector(
    (state) => state.sellRequestReducer.isSellRequestModalOpen
  );

  const brand = watch("brands");
  const model = watch("modelId");
  const [managementValue, setManagementValue] = useState<string | null>(
    "certified-managed"
  );

  const [searchShopBy, setSearchShopBy] = useState<any>("pic");
  const [selectedShop, setIsSelectedShop] = useState<string>("");
  const [shopId, setShopId] = useState<string>("");
  const [shopName, setShopName] = useState<string>("");
  const [shopAddress, setShopAddress] = useState<string>("");
  const [postSellRequest] = usePostRequestMutation();
  const { data: carDetailData } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}`
  );
  const { data: countries } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}/locations`
  );

  const { data: states } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}/locations/Malaysia`
  );

  const { data: carModelsConditional } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}/search/models?parentId=${brand?.id}`,
    { skip: !brand }
  );
  const { data: carVariantConditional } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}/search/variants?parentId=${model?.id}`,
    { skip: !model }
  );

  const [trigger, data] = useLazyGetQueryQuery();

  const shopSearchResult: ShopSearchT = data.data;

  useEffect(() => {
    const currentShop = selectedShop.split(" ");

    setShopId(currentShop[0]);
    setShopName(currentShop[1]?.replaceAll(",", ""));
  }, [selectedShop]);

  useEffect(() => {
    if (managementValue !== "certified-managed") {
      setIsShopModalOpen(true);
    } else {
      setIsShopModalOpen(false);
    }
  }, [managementValue]);

  useEffect(() => {
    reset({
      country: { id: 1, title: "Malaysia" },
    });
  }, [reset]);
  if (!carDetailData) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 10,
        }}
      >
        {/* <CircularProgress size={100} sx={{ color: "#F5903D" }} /> */}
      </Box>
    );
  }

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setLoading(true);
    const sellReqData: selRequestSubmitT = {
      price: data.price ? +data.price.replaceAll(",", "") : undefined,
      management: managementValue || "certified-managed",
      car: {
        registrationNo: data.registrationNo,
        bodyId: +data.bodyId,
        colorId: +data.colorId,
        gearId: +data.gearId,
        variantId: data?.variantId?.id,
        modelId: data.modelId.id,
        manufactureDate: {
          year: +data.year,
          month: 1,
        },
      },
      contacts: [
        {
          type: "HOME",
          stateId: data.state.id,
          city: data.city,
          postalCode: data.postalCode,
          addressLine: data.addressLine,
        },
      ],
      sellerInfo: {
        name: data.name,
        email: data.email,
        phoneNumber: data.phoneNumber,
      },
    };
    if (managementValue !== "certified-managed") {
      sellReqData.shopId = shopId;
    }

    postSellRequest({
      url: `${REST_STAFF_ENDPOINTS.sellRequest}/simple`,
      payload: sellReqData,
    }).then((res: any) => {
      setLoading(false);
      if ("error" in res && res.error && res.error.data) {
        dispatch(
          setToaster({
            isOpen: true,
            title: res.error?.data?.message || "Submission Failed",
            type: "error",
          })
        );
      } else {
        setIsSellRequestModalOpen(false);
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully car sell requested is submitted",
            type: "success",
          })
        );
        navigate(`/sell-requests/${res.data.request.id}`, { replace: true });
      }
    });
  };

  const handleChange = (value: React.ChangeEvent<HTMLInputElement>) => {
    const data = (value.target as HTMLInputElement).value;
    setManagementValue(data);
  };

  const onSearchInputHandler = (text: string) => {
    if (text.length && text.length > 1) {
      const queryParams = `${searchShopBy}=${text}`;
      trigger(`${REST_STAFF_ENDPOINTS.shop}?${queryParams}`);
    }
  };

  return (
    <>
      {loading && <Loading />}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={isSellRequestModalOpen}
        onClose={() => dispatch(setIsSellRequestModalOpen(false))}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* 
      <ShopModal
        isOpen={isShopModalOpen}
        shopList={true}
        onCloseHandler={onCloseHandler}
      /> */}
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ p: 10 }}>
          <Box pb={2}>
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography variant="h6" color="#1f243a" fontWeight="bold">
                  Management
                </Typography>
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="certified-managed"
                name="radio-buttons-group"
                onChange={handleChange}
              >
                <FormControlLabel
                  value="certified-managed"
                  control={<Radio />}
                  label="METACAR certified & Managed"
                />

                <FormControlLabel
                  value="certified-only"
                  control={<Radio />}
                  label="METACAR certified & Self Managed"
                />
                <FormControlLabel
                  value="uncertified"
                  control={<Radio />}
                  label="Uncertified & Self Managed"
                />
              </RadioGroup>
            </FormControl>

            {isShopModalOpen ? (
              <Box sx={{ pt: 4 }}>
                <Typography variant="h6">Search Shop</Typography>
                <Box
                  sx={{
                    display: "flex",
                    p: 2,
                    gap: 6,
                  }}
                >
                  <Box sx={{ minWidth: 300 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Search By
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={searchShopBy}
                        label="Search By"
                        onChange={(item) => setSearchShopBy(item.target.value)}
                      >
                        <MenuItem value={"pic"}>By PIC</MenuItem>
                        <MenuItem value={"email"}>By Email</MenuItem>
                        <MenuItem value={"name"}>By Name</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Autocomplete
                    disablePortal
                    fullWidth
                    id="combo-box-demo"
                    options={
                      shopSearchResult &&
                      shopSearchResult.shops &&
                      shopSearchResult.shops.length
                        ? shopSearchResult.shops.map((option) => {
                            return `${option.id} ,${option.name} ,Managed By ${option.pic.name} `;
                          })
                        : []
                    }
                    sx={{ width: 500 }}
                    onChange={(_event, value) => {
                      value && setIsSelectedShop(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Shop"
                        onChange={(event) =>
                          onSearchInputHandler(event.target.value)
                        }
                      />
                    )}
                  />
                </Box>
              </Box>
            ) : null}
          </Box>
          {shopName && isShopModalOpen ? (
            <Box sx={{ p: 2 }}>
              <Typography sx={{ pb: 3, fontWeight: "bold" }}>
                Selected Shop{" "}
              </Typography>
              <ShopDetail shopName={shopName} shopAddress="" />{" "}
            </Box>
          ) : null}

          <Box
            display={"flex"}
            gap={10}
            width={"100%"}
            flexWrap={{ xs: "wrap", md: "nowrap" }}
            flexDirection={{ xs: "column-reverse", md: "row" }}
          >
            <Box flexGrow={1} width={"100%"}>
              <Typography
                variant="h6"
                color={"rgba(0, 0, 0, 0.7)"}
                borderBottom={"2px solid rgba(0, 0, 0, 0.3)"}
                mb={2}
                fontWeight={"bold"}
              >
                Car Detail
              </Typography>

              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <TextField
                  margin="normal"
                  fullWidth
                  sx={{ mt: 1 }}
                  label="Intended Selling Price RM"
                  {...register("price")}
                  InputProps={{
                    inputComponent: NumericFormatCustom as any,
                  }}
                  error={!!errors?.price?.message}
                  helperText={errors?.price?.message}
                />
                <TextField
                  {...register("registrationNo", RULES.REGISTRATION_NUMBER)}
                  margin="normal"
                  fullWidth
                  id="registrationNo"
                  label="Registration Number"
                  autoComplete="registrationNo"
                  
                  error={!!errors?.registrationNo?.message}
                  helperText={errors?.registrationNo?.message}
                  sx={{ m: 0 }}
                />
                <AutoCompleteFormHook
                  options={carDetailData?.brands}
                  disabled={!carDetailData.brands}
                  name="brands"
                  label="Brands"
                  control={control}
                  defaultValue={""}
                  required
                />

                <AutoCompleteFormHook
                  options={carModelsConditional?.models}
                  disabled={!carModelsConditional?.models.length}
                  name="modelId"
                  label="Models"
                  control={control}
                  defaultValue={""}
                  required
                />

                <AutoCompleteFormHook
                  disabled={!carVariantConditional?.variants.length}
                  options={carVariantConditional?.variants}
                  name="variantId"
                  label="Variant"
                  control={control}
                  defaultValue={""}
                />

                {carDetailData?.bodies.length ? (
                  <SelectFormHook
                    id="bodyId"
                    name="bodyId"
                    label="Body"
                    control={control}
                    defaultValue={""}
                    required
                  >
                    {carDetailData.bodies.map((item: any) => {
                      return <MenuItem value={item.id}>{item.title}</MenuItem>;
                    })}
                  </SelectFormHook>
                ) : null}

                {carDetailData?.colors.length ? (
                  <SelectFormHook
                    id="colorId"
                    name="colorId"
                    label="Color"
                    control={control}
                    defaultValue={""}
                    required
                  >
                    {carDetailData.colors.map((item: any) => {
                      return <MenuItem value={item.id}>{item.title}</MenuItem>;
                    })}
                  </SelectFormHook>
                ) : null}

                {carDetailData?.gears.length ? (
                  <SelectFormHook
                    id="gearId"
                    name="gearId"
                    label="Transmission"
                    control={control}
                    defaultValue={""}
                    required
                  >
                    {carDetailData.gears.map((item: any) => {
                      return (
                        <MenuItem value={item.id}>
                          {item.title.toString().toUpperCase()}
                        </MenuItem>
                      );
                    })}
                  </SelectFormHook>
                ) : null}

                <Box>
                  <FormControl sx={{ width: "100%" }}>
                    <Box display={"flex"} gap={10}>
                      <SelectFormHook
                        id="year"
                        name="year"
                        label="Manufacturing Year"
                        control={control}
                        defaultValue={""}
                        required
                      >
                        {basicYears.map((item: any) => {
                          return (
                            <MenuItem value={item.id}>
                              {item.title.toString().toUpperCase()}
                            </MenuItem>
                          );
                        })}
                      </SelectFormHook>
                    </Box>
                  </FormControl>
                </Box>
              </Box>
            </Box>

            <Box flexGrow={1} width={"100%"}>
              <Typography
                variant="h6"
                color={"rgba(0, 0, 0, 0.7)"}
                fontWeight="bold"
                borderBottom={"2px solid rgba(0, 0, 0, 0.3)"}
                mb={1}
              >
                Contact Information
              </Typography>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <TextField
                  {...register("name", RULES.COMMON_RULES_STRING)}
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Full Name"
                  error={!!errors?.name?.message}
                  helperText={errors?.name?.message}
                  sx={{ mb: 2 }}
                />
                <TextField
                  {...register("email", RULES.EMAIL)}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  error={!!errors?.email?.message}
                  helperText={errors?.email?.message}
                  sx={{ m: 0 }}
                />
                <TextField
                  {...register("phoneNumber", RULES.COMMON_RULES_STRING)}
                  margin="normal"
                  fullWidth
                  id="phoneNumber"
                  label="Contact Number"
                  error={!!errors?.phoneNumber?.message}
                  helperText={errors?.phoneNumber?.message}
                  sx={{ m: 0 }}
                />
              </Box>
              <Typography
                variant="h6"
                color={"rgba(0, 0, 0, 0.7)"}
                mt={2}
                mb={2}
                borderBottom={"2px solid rgba(0, 0, 0, 0.3)"}
                fontWeight={"bold"}
              >
                Inspection Address
              </Typography>
              <Box display={"flex"} flexDirection={"column"} gap={2}>
                <TextField
                  {...register("addressLine", RULES.ADDRESS_LINE)}
                  margin="normal"
                  fullWidth
                  id="addressLine"
                  label="Address"
                  autoComplete="postalCode"
                  
                  error={!!errors?.addressLine?.message}
                  helperText={errors?.addressLine?.message}
                  sx={{ mt: 0 }}
                  multiline
                  // rows={4}
                />
                <TextField
                  {...register("city", RULES.CITY)}
                  margin="normal"
                  fullWidth
                  id="city"
                  label="City"
                  autoComplete="city"
                  
                  error={!!errors?.city?.message}
                  helperText={errors?.city?.message}
                  sx={{ mt: 0 }}
                />

                {/* <SelectFormHook
                id="type"
                name="type"
                label="Contact Type"
                control={control}
                defaultValue={""}
                required
              >
                {["WORK", "HOME"].map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </SelectFormHook> */}

                <TextField
                  {...register("postalCode", RULES.POSTAL_CODE)}
                  margin="normal"
                  fullWidth
                  id="postalCode"
                  label="Postal Code"
                  autoComplete="postalCode"
                  
                  error={!!errors?.postalCode?.message}
                  helperText={errors?.postalCode?.message}
                  sx={{ mt: 0 }}
                />
                <AutoCompleteFormHook
                  disabled={!countries?.list.length}
                  options={countries?.list}
                  name="country"
                  label="Country"
                  control={control}
                  defaultValue={""}
                  required
                  readOnly
                />
                <AutoCompleteFormHook
                  disabled={!states?.list.length}
                  options={states?.list}
                  name="state"
                  label="state"
                  control={control}
                  defaultValue={""}
                  required
                />
              </Box>
            </Box>
          </Box>
          <Container sx={{ mt: 4 }} maxWidth="sm">
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ float: "right" }}
            >
              Submit
            </Button>
          </Container>
        </Box>
      </Dialog>
    </>
  );
};
