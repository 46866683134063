import { apiSlice } from "../api/api-slice";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth.slicer";
import { rtkQueryErrorLogger } from "../api/rtkQueryErrorLogger";
import basicItemReducers from "./features/basic-items.slicer";
import sellRequestReducer from "./features/sell-requests.slicer";
import buyRequestReducer from "./features/buy-requests.slicer";

export const store = configureStore({
  reducer: {
    authReducer,
    basicItemReducers,
    sellRequestReducer,
    buyRequestReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([apiSlice.middleware, rtkQueryErrorLogger]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
