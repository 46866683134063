import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Card } from "../../shared/card";

import { usePatchRequestMutation } from "../../../../utility/redux/api/api-slice";
import { Box, TextField } from "@mui/material";
import { sellRequestDataT } from "../../../pages/sell-request-details/types";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";

interface Inputs {
  title: string;
  description: string;
  additionalInfo: any;
}
interface Props {
  requestId: number;
  sellRequestData: sellRequestDataT;
  refetch: () => void;
}

export const MetaDataCarDetail: React.FC<Props> = ({
  requestId,
  sellRequestData,
  refetch,
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Inputs>();
  const [patchData, putDataResult] = usePatchRequestMutation();

  const dispatch = useDispatch();

  const onSubmit = async (data: Inputs) => {
    const payload = {
      title: data.title,
      description: data.description,
      additionalInfo: data.additionalInfo,
    };
    const result: any = await patchData({
      url: `internal/sell-requests/${requestId}/meta-data`,
      payload,
    });
    if ("error" in result && result.error && result.error.data) {
      dispatch(
        setToaster({
          isOpen: true,
          title: result.error?.data?.message || "Submission Failed",
          type: "error",
        })
      );
    } else {
      refetch();
      dispatch(
        setToaster({
          isOpen: true,
          title: "Successfully Meta data updated",
          type: "success",
        })
      );
    }
  };

  useEffect(() => {
    reset({
      title: sellRequestData.metaData?.title,
      description: sellRequestData.metaData?.description,
      additionalInfo: sellRequestData.metaData?.additionalInfo,
    });
  }, [reset]);

  return (
    <div className="seller-detail-container">
      <Card header="Meta Data">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ margin: "auto" }}
        >
          <div className="row">
            <div className="col-sm-6 col-md-3">
              <TextField
                {...register("title")}
                margin="normal"
                fullWidth
                id="title"
                label={"Title"}
                autoComplete="title"
                
                required
                InputLabelProps={{ shrink: true }}
                error={!!errors?.title?.message}
                helperText={errors?.title?.message}
                sx={{ m: 0 }}
              />
            </div>
            <div className="col-sm-6 col-md-4">
              <TextField
                {...register("description")}
                margin="normal"
                fullWidth
                id="description"
                label={"Description"}
                autoComplete="description"
                
                required
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
                error={!!errors?.description?.message}
                helperText={errors?.description?.message}
                sx={{ m: 0 }}
              />
            </div>
            <div className="col-sm-6 col-md-4">
              <TextField
                {...register("additionalInfo")}
                margin="normal"
                fullWidth
                id="additionalInfo"
                label={"Additional Info"}
                autoComplete="additionalInfo"
                
                required
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
                error={!!errors?.additionalInfo?.message}
                sx={{ m: 0 }}
              />
            </div>
          </div>

          <LoadingButton
            loading={putDataResult.isLoading}
            sx={{ float: "right" }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </div>
  );
};
