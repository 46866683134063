import {
  useAppDispatch,
  useAppSelector,
} from "../../../../utility/redux/state/hooks";
import { Button } from "../../shared/button";
import { DropDown } from "../../shared/drop-down";
import "./styles.scss";
import { IoCarSportSharp } from "react-icons/io5";
import { FaFilter } from "react-icons/fa";
import { TextBox } from "../../shared/TextBox";
import { useForm } from "react-hook-form";
import { FC, useEffect, useState } from "react";

import { AddCircle, FilterAlt, FilterAltOff } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { setIsSellRequestModalOpen } from "../../../../utility/redux/state/features/sell-requests.slicer";
import {
  useGetQueryQuery,
  useLazyGetQueryQuery,
} from "../../../../utility/redux/api/api-slice";
import { ENDPOINTS } from "../../../../utility/redux/state/features/actions/endpoints";
import { ShopSearchT } from "../../sell-requests/NewSellRequestModal/types";
import { AutoCompleteFormHook } from "../../shared/AutoCompleteFormHook";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import { statusesT } from "../../sell-requests/general-details-buy-req/types";

export const managementType = [
  { name: "METACAR certified & Managed", value: "certified-managed" },
  { name: "METACAR certified & Self Managed", value: "certified-only" },
  { name: "Uncertified & Self Managed", value: "uncertified" },
];

interface Props {
  onChangeFilter: (payload: any) => void;
  buyReq?: boolean;
  countBuyReq?: number;
  sellReq?: boolean;
}
export const BuyRequestFilter: FC<Props> = ({
  onChangeFilter,
  buyReq,
  countBuyReq,
  sellReq,
}) => {
  const dispatch = useAppDispatch();

  const [selectedBrand, setSelectedBrand] = useState("");
  const [currentFilter, persistFilter] = useState<any>();

  const { data: statuses } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}/buy-request/statuses`
  );
  const statusData: statusesT = statuses;

  const count = useAppSelector((state) => state.buyRequestReducer.count);

  const currentFilterQueyParams = useAppSelector(
    (state) => state.buyRequestReducer.queryParams
  );
  const { data: basicData } = useGetQueryQuery(`${ENDPOINTS.BASIC_ITEMS}`);

  const { register, handleSubmit, watch, control, setValue, reset, getValues } =
    useForm({
      defaultValues: {
        brand: "",
        status: "",
        model: "",
        registration_no: "",
        start_date: "",
        end_date: "",
        buyer_name: "",
        tracking_code: "",
        management: "",
        buyer_phone: "",
        buyer_email: "",
        tag: "",
        id: "",
        grade: "",
        Models: "",
        state_id: "",
      },
    });

  const brand = watch("brand");

  const { data: carModelsConditional } = useGetQueryQuery(
    `${ENDPOINTS.BASIC_ITEMS}/search/models?parent=${brand}`,
    { skip: !brand }
  );


  useEffect(() => {
    setTimeout(() => {
      reset({
        id: currentFilterQueyParams?.id,
        grade: currentFilterQueyParams?.grade,
        status: currentFilterQueyParams?.status,
        model: currentFilterQueyParams?.model,
        brand: currentFilterQueyParams?.brand,
        registration_no: currentFilterQueyParams?.registration_no,
        start_date: currentFilterQueyParams?.start_date,
        end_date: currentFilterQueyParams?.end_date,
        buyer_name: currentFilterQueyParams?.buyer_name,
        tracking_code: currentFilterQueyParams?.tracking_code,
        management: currentFilterQueyParams?.management,
        buyer_phone: currentFilterQueyParams?.buyer_phone,
        buyer_email: currentFilterQueyParams?.buyer_email,
        tag: currentFilterQueyParams?.tag,
      });
    }, 100);
  }, [reset, currentFilterQueyParams]);

  useEffect(() => {
    if (selectedBrand && selectedBrand !== brand) {
      setValue("Models", "");
    }
  }, [selectedBrand]);

  const onSubmitHandle = (data: any) => {
    reset();
    setSelectedBrand("");
    if (data.state_id) {
      data.state_id = data.state_id.id;
    }

    persistFilter(data);
    onChangeFilter(data);
    if (Object.keys(data).length === 0) {
      reset({
        registration_no: "",
        start_date: "",
        end_date: "",
        buyer_name: "",
        tracking_code: "",
        management: "",
        buyer_phone: "",
        buyer_email: "",

        id: "",
      });
    }
  };

  const setSellRequestModal = () => {
    dispatch(setIsSellRequestModalOpen(true));
  };

  const onSearchInputHandler = (text: string) => {

  };
  return (
    <Box
      className="request-filter-container"
      style={{ backgroundColor: "#FAF9F6" }}
    >
      <form onSubmit={handleSubmit(onSubmitHandle)}>
        <Box display={"flex"} flexDirection={"column"} gap={4}>
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Box display={"flex"} gap={2} flexWrap={"wrap"}>
              <Box>
                <DropDown
                  name="status"
                  control={control}
                  defaultValue={currentFilter?.status}
                  items={
                    statusData?.statuses?.map((st) => ({
                      id: st,
                      title: st,
                    })) ?? []
                  }
                  label="Status"
                  register={{ ...register("status") }}
                />
              </Box>
              <Box>
                <DropDown
                  name="brand"
                  control={control}
                  items={basicData?.brands ?? []}
                  label="Brand"
                  register={{ ...register("brand") }}
                  onChangeHandler={(event: any) => {
                    return setSelectedBrand(event.target.value);
                  }}
                />
              </Box>
              <Box>
                <DropDown
                  name="model"
                  control={control}
                  defaultValue={currentFilter?.model}
                  items={carModelsConditional?.models ?? []}
                  label="Models"
                  register={{ ...register("model") }}
                />
              </Box>

              <Box>
                <TextBox
                  width={160}
                  onKeyPress={(data: any) => {
                    if (data.charCode === 13) {
                      handleSubmit(onSubmitHandle);
                    }
                  }}
                  label="Registration No"
                  register={{ ...register("registration_no") }}
                // value={
                //   getValues("registration_no")
                //     ? getValues("registration_no")
                //     : undefined
                // }
                />
              </Box>
              <Box>
                <TextBox
                  label="Start Date"
                  type="date"
                  register={{ ...register("start_date") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  label="End Date"
                  type="date"
                  register={{ ...register("end_date") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  label="Tracking Code"
                  register={{ ...register("tracking_code") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  label="Car Id"
                  register={{ ...register("id") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  label="Buyer Name"
                  register={{ ...register("buyer_name") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={160}
                  label="Buyer Phone No."
                  register={{ ...register("buyer_phone") }}
                />
              </Box>
              <Box>
                <TextBox
                  width={260}
                  fullWidth
                  label="Buyer Email"
                  register={{ ...register("buyer_email") }}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", gap: 3 }}>
                <Button color="success" label="Apply" icon={<FaFilter />} />
                <Button
                  color="success"
                  type="button"
                  onClick={() => {
                    onSubmitHandle({});
                  }}
                  label="Clear Filters"
                  icon={<FilterAltOff />}
                />
              </Box>

              {!buyReq ? (
                <Button
                  type="button"
                  color="success"
                  variant="contained"
                  onClick={() => {
                    setSellRequestModal();
                  }}
                  label="Create Sell Request"
                  icon={<AddCircle />}
                />
              ) : null}
            </Box>
          </Box>
        </Box>
      </form>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          mt: 2,
        }}
      >
        <span>
          {" "}
          Available Cars:{" "}
          <b style={{ color: "green" }}> {buyReq ? countBuyReq : count} </b>
          <IoCarSportSharp />
        </span>
      </Box>
    </Box>
  );
};
