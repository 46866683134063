import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { getToken } from "./auth";
import { ENDPOINTS } from "./endpoints";

const baseURL = process.env.REACT_APP_BACKEND_API;
const buyRequestBaseUrl = baseURL + ENDPOINTS.BUY_REQUESTS;
function makeHeader() {
  return { authorization: `bearer ${getToken()}` };
}
export const fetchBuyRequestApi = createAsyncThunk(
  "GET /buy-requests",
  async (filter: Record<string, string | number>, thunkAPI) => {
    const params = [];
    const filterKeys = Object.keys(filter);
    for (const key of filterKeys) {
      if (filter[key]) {
        params.push(`${key}=${filter[key]}`);
      }
    }

    const response = await axios.get(
      buyRequestBaseUrl + "?" + params.join("&"),
      {
        headers: makeHeader(),
      }
    );
    return response.data;
  }
);

export const fetchBuyRequestByIdApi = createAsyncThunk(
  "GET /buy-requests/id",
  async (id: string, thunkAPI) => {
    const response = await axios.get(buyRequestBaseUrl + `/${id}`, {
      headers: makeHeader(),
    });
    return response.data;
  }
);
