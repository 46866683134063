import { useEffect } from "react";
import { Box, TextField, MenuItem } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Controller, useForm, useWatch } from "react-hook-form";
import { Card } from "../../shared/card";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import {
  useGetQueryQuery,
  usePostRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { SelectFormHook } from "../../shared/SelectFormHook";
import { AutoCompleteFormHook } from "../../shared/AutoCompleteFormHook";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

interface IProps {
  buyRequestId?: string;
  buyRequestData: any;
  staffList?: { id: number; displayName: string }[];
  states: {
    status: number;
    list: { id: number; title: string }[];
    type: string;
  };
}

interface DefaultLocation {
  address_line: string;
  city: string;
  country: string;
  name: string;
  post_code: string;
  state: string;
}
interface Inputs {
  scheduled_date: string;
  staffId: string;
  address_line?: string;
  default_location?: DefaultLocation;
  city?: string;
  state?: { id: string; title: string };
  country?: { id: string; title: string };
  postalCode?: string;
}

export default function Puspakom({
  buyRequestId,
  buyRequestData,
  staffList = [],
  states = { status: 0, list: [], type: "" },
}: IProps) {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const [updatePuspakom, result] = usePostRequestMutation();

  const {
    data: locationData,
    refetch: refetchDefaultLocation,
    isLoading: defaultLocationIsLoading,
  } = useGetQueryQuery(`${REST_STAFF_ENDPOINTS.staticData}/admin-config`);
  const defaultLocationData =
    locationData?.result?.["admin-data"]?.[0]?.puspakom;

  const {
    data: puspakomData,
    refetch,
    isLoading,
  } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.buyRequest}/${buyRequestId}/puspakom`,
    { skip: !buyRequestId }
  );

  useEffect(() => {
    if (puspakomData?.puspakoms?.length > 0) {
      const puspakom = puspakomData.puspakoms[0];
      const { staff, dateTime, address = {} } = puspakom;
      const matchedState = states?.list.find(
        (st) => st.title.toString() === address?.state?.toString()
      );
      reset({
        staffId: staff?.id?.toString() || "",
        scheduled_date: dateTime || null,
        address_line: address?.addressLine || "",
        city: address?.city || "",
        state: matchedState
          ? { id: matchedState.id.toString(), title: matchedState.title }
          : undefined,
        country: address?.country
          ? { id: "Malaysia", title: "Malaysia" }
          : undefined,
        postalCode: address?.postalCode || "",
      });
    }
  }, [puspakomData, reset]);

  const onSubmit = (data: Inputs) => {
    const payload = {
      address_line: data.address_line,
      country: data.country?.id,
      state: data.state?.title,
      city: data.city,
      postalCode: data.postalCode,
      staffId: data.staffId,
      dateTime: data.scheduled_date,
    };

    updatePuspakom({
      url: `${REST_STAFF_ENDPOINTS.buyRequest}/${buyRequestId}/puspakom`,
      payload,
    }).then((res: any) => {
      if (res.error) {
        dispatch(
          setToaster({
            isOpen: true,
            title: res.error?.data?.message || "Failed to update puspakom",
            type: "error",
          })
        );
      } else {
        dispatch(
          setToaster({
            isOpen: true,
            title: "Puspakom updated successfully",
            type: "success",
          })
        );
        refetch();
      }
    });
  };

  /* use default location to populate fields */
  const selectedLocation = useWatch({ control, name: "default_location" });

  useEffect(() => {
    if (selectedLocation) {
      const locationObj =
        typeof selectedLocation === "string"
          ? JSON.parse(selectedLocation)
          : selectedLocation;

      if (typeof locationObj === "object") {
        setValue("address_line", locationObj.address_line || "");
        setValue("city", locationObj.city || "");
        setValue("country", { id: "Malaysia", title: "Malaysia" });
        setValue("postalCode", locationObj.post_code || "");

        const matchedState = states?.list.find(
          (st) => st.title.toLowerCase() === locationObj.state?.toLowerCase()
        );

        if (matchedState) {
          setValue("state", {
            id: matchedState.id.toString(),
            title: matchedState.title,
          });
        } else {
          setValue("state", undefined);
        }
      }
    }
  }, [selectedLocation]);

  return (
    <div className="car-detail-container">
      <Card header="Puspakom">
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ margin: "auto" }}
        >
          <Box
            display="grid"
            gridTemplateColumns={{
              xs: "1fr",
              sm: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(4, 1fr)",
              xl: "repeat(4, 1fr)",
            }}
            gap={3}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              gridColumn="span 1"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Controller
                  name="scheduled_date"
                  control={control}
                  rules={{ required: "Scheduled date is required" }}
                  defaultValue={""}
                  render={({ field }) => (
                    <DateTimePicker
                      label="Scheduled Date"
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(value) => {
                        field.onChange(value ? value.toISOString() : null);
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          error: !!errors.scheduled_date,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              gridColumn="span 1"
            >
              <SelectFormHook
                id="staffId"
                name="staffId"
                label="PIC"
                control={control}
                defaultValue=""
                helperText={errors.staffId?.message}
                required
              >
                {staffList?.map((staff) => (
                  <MenuItem key={staff.id} value={staff.id}>
                    {staff.displayName}
                  </MenuItem>
                ))}
              </SelectFormHook>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              gridColumn={{ xs: "span 1", sm: "span 1", md: "span 2" }}
            >
              <SelectFormHook
                id="default_location"
                name="default_location"
                label="Default Location"
                control={control}
                defaultValue=""
                helperText={errors.default_location?.message}
              >
                {defaultLocationData?.addresses?.map(
                  (address: DefaultLocation, index: number) => (
                    <MenuItem key={index} value={JSON.stringify(address)}>
                      {address.name}
                    </MenuItem>
                  )
                )}
              </SelectFormHook>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              gridColumn={{
                xs: "span 1",
                sm: "span 1",
                md: "span 2",
                lg: "span 4",
                xl: "span 4",
              }}
            >
              <TextField
                {...register("address_line")}
                margin="normal"
                fullWidth
                id="addressLine"
                label="Address Line"
                autoComplete="addressLine"
                sx={{ m: 0 }}
                multiline
                rows={1}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              gridColumn="span 1"
            >
              <TextField
                {...register("city")}
                margin="normal"
                fullWidth
                id="city"
                label="City"
                autoComplete="city"
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              gridColumn="span 1"
            >
              <AutoCompleteFormHook
                options={states?.list}
                disabled={false}
                name="state"
                label="State"
                control={control}
                defaultValue={""}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              gridColumn="span 1"
            >
              <AutoCompleteFormHook
                options={[{ id: "Malaysia", title: "Malaysia" }]}
                disabled={false}
                name="country"
                label="Country"
                control={control}
                defaultValue={""}
              />
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              gridColumn="span 1"
            >
              <TextField
                {...register("postalCode")}
                margin="normal"
                fullWidth
                id="postalCode"
                label="PostalCode"
                autoComplete="postalCode"
                sx={{ m: 0 }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Box>

          <LoadingButton
            loading={result.isLoading}
            sx={{ float: "right", mt: 1 }}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </Box>
      </Card>
    </div>
  );
}
