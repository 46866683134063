import React, { useEffect } from "react";
import { useGetQueryQuery } from "../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../utility/redux/api/endpoints";
import { useNavigate, useParams } from "react-router-dom";

import "./styles.scss";
import { PageHeader } from "../../components/shared/page-header";
import { CarDetail } from "../../components/sell-requests/car-details/car-detail";
import SellerDetails from "../../components/sell-requests/seller-details/seller-details";
import { GeneralDetails } from "../../components/sell-requests/general-details/general-details";
import { FinancialDetails } from "../../components/sell-requests/fin-details/fin-details";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../utility/redux/state/hooks";
import { fetchSellRequestApi } from "../../../utility/redux/state/features/actions/sell-requests";
import { CustomerAdvisor } from "../../components/sell-requests/customer-advisor/customer-advisor";
import { sellRequestDataT } from "./types";
import { Toaster } from "../../components/General/Toaster";
import { InspectionDetails } from "../../components/sell-requests/inspection-details/inspection-details";
import { InsuranceClaim } from "../../components/sell-requests/insurance-claim/insurance-claim";
import { GeneralPhotosSellRequest } from "../../components/sell-requests/general-photos/general-photo";
import { Box, CircularProgress } from "@mui/material";
import { MetaDataCarDetail } from "../../components/sell-requests/metaData/MetaDataCarDetail";
import YoutubeLinks from "../../components/sell-requests/youtube-links/youtube-links";

const SellRequestDetails: React.FC = (props) => {
  const toaster = useAppSelector((state) => state.authReducer.toaster);
  let { id } = useParams();
  const dispatch = useAppDispatch();

  const { data: { request: sellerRequest } = {}, refetch } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.sellRequest}/${id}`,
    {
      skip: !id || id === "",
    }
  );

  const sellRequestData: sellRequestDataT = sellerRequest;

  useEffect(() => {
    dispatch(
      fetchSellRequestApi({
        skip: 0,
        take: 0,
      })
    );
  }, []);

  return (
    <div className="sell-request-detail-container">
      <Toaster
        isOpen={toaster.isOpen}
        title={toaster.title}
        description={toaster.description}
        type={toaster.type ?? "success"}
      />
      <PageHeader label="Sell Request Detail" />

      {sellRequestData ? (
        <>
          <GeneralDetails
            requestId={sellRequestData?.id}
            createdAt={sellRequestData?.createdAt}
            trackingCode={sellRequestData?.trackingCode}
            status={sellRequestData?.status}
            sellRequestData={sellRequestData}
          />

          <FinancialDetails
            requestId={sellRequestData?.id}
            finalPrice={sellRequestData?.sellerFinalizedPrice?.amount}
            currency={sellRequestData?.sellerFinalizedPrice?.currency}
            proposedPrice={sellRequestData?.sellerPropsedPrice?.amount}
            installmentAmount={sellRequestData?.loan?.monthlyInstallment}
            isBelowMarketPrice={sellRequestData?.isBelowMarketPrice}
            loanInfo={sellRequestData?.loan}
            refetch={refetch}
          />
          <CarDetail
            requestId={sellRequestData?.id}
            sellRequestData={sellRequestData}
          />
          <YoutubeLinks requestId={sellRequestData?.id} />
          <MetaDataCarDetail
            requestId={sellRequestData.id}
            sellRequestData={sellRequestData}
            refetch={refetch}
          />
          <SellerDetails
            requestId={sellRequestData.id}
            sellRequestData={sellRequestData}
          />

          <CustomerAdvisor
            requestId={sellRequestData.id}
            sellRequestData={sellRequestData}
            refetch={refetch}
          />

          <InspectionDetails
            requestId={sellRequestData.id}
            sellRequestData={sellRequestData}
            refetch={refetch}
          />
          <InsuranceClaim
            requestId={sellRequestData.id}
            sellRequestData={sellRequestData}
            refetch={refetch}
          />

          <GeneralPhotosSellRequest
            requestId={sellRequestData.id}
            sellRequestData={sellRequestData}
            refetch={refetch}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 10,
          }}
        >
          <CircularProgress size={100} sx={{ color: "#F5903D" }} />{" "}
        </Box>
      )}
    </div>
  );
};
export default SellRequestDetails;
