import { Autocomplete, FormControl, TextField } from "@mui/material";
import { FC } from "react";

import { Control, Controller, FieldValues } from "react-hook-form";

interface Props {
  name: string;
  label: string;
  control?: Control<any, any>;
  defaultValue?: string;
  options?: any[];
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
}

export const AutoCompleteFormHook: FC<Props> = ({
  name,
  label,
  control,
  defaultValue,
  options,
  disabled,
  required,
  readOnly,
  ...props
}) => {
  return (
    <FormControl {...props} sx={{ width: "100%" }}>
      <Controller
        name={name}
        defaultValue={defaultValue}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <Autocomplete
              isOptionEqualToValue={() => true}
              disabled={disabled}
              readOnly={readOnly}
              getOptionLabel={(option) => (option.title ? option.title : "1")}
              options={options || []}
              renderInput={(params) => (
                <TextField {...params} label={label} required={required} />
              )}
              onChange={(e, data) => onChange(data)}
              {...props}
              value={value ? value : null}
            />
          );
        }}
      />
    </FormControl>
  );
};
