import {
  Box,
  CircularProgress,
  Container,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";
import { SelectFormHook } from "../../../components/shared/SelectFormHook";
import { FC, useEffect, useState } from "react";
import {
  useDeleteRequestMutation,
  useGetQueryQuery,
  usePutRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { GeneralInfoT } from "../../sell-request-details/types";
import { AddPhotoAlternate } from "@mui/icons-material";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import { useDispatch } from "react-redux";
import LoadingButton from "@mui/lab/LoadingButton";
import { heicConvertor } from "./heic-convertor";
import { Grade } from "./grade";
import Dropzone from "react-dropzone";

interface Inputs {
  generalRemark: string;
  noOfOwners: string;
  hasBooklet: string;
  hasSpareKey: string;
  mileage: string;
  spare_key_img: any;
  mileage_img: any;
  booklet_img: any;
  grade: string;
}

interface Props {
  sellRequestId: string;
  reportId: string;
}
export const InspectionGeneral: FC<Props> = ({ sellRequestId, reportId }) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<Inputs>();
  const [putData, result] = usePutRequestMutation();
  const dispatch = useDispatch();
  const [deleteImage, deleteResult] = useDeleteRequestMutation();
  const [selectedMilageImage, setSelectedMileageImage] = useState<any>(null);
  const [selectedBookletImage, setSelectedBookletImage] = useState<any>(null);
  const [selectedSpareKeyImage, setSelectedSpareKeyImage] = useState<any>(null);

  const { data, refetch } = useGetQueryQuery(
    `/internal/inspection-reports/${reportId}/general`
  );

  const onSelectMilageImage = async (file: File) => {
    if (file.type === "image/heic" || file.type === "image/heif") {
      setSelectedMileageImage(await heicConvertor(file, true));
    } else {
      setSelectedMileageImage(file);
    }
  };

  const onSelectSpareKeyImage = async (file: File) => {
    if (file.type === "image/heic" || file.type === "image/heif") {
      setSelectedSpareKeyImage(await heicConvertor(file, true));
    } else {
      setSelectedSpareKeyImage(file);
    }
  };

  const onSelectBookletImage = async (file: File) => {
    if (file?.type === "image/heic" || file?.type === "image/heif") {
      setSelectedBookletImage(await heicConvertor(file, true));
    } else {
      setSelectedBookletImage(file);
    }
  };

  const generalData: GeneralInfoT = data?.generalInfo;

  useEffect(() => {
    if (generalData) {
      reset({
        generalRemark: generalData.generalRemark,
        noOfOwners: generalData.selectedFeatures.filter(
          (item) => item.name === "noOfOwners"
        )[0]?.value,

        hasBooklet: generalData.selectedFeatures.filter(
          (item) => item.name === "hasBooklet"
        )[0]?.value,
        hasSpareKey: generalData.selectedFeatures.filter(
          (item) => item.name === "hasSpareKey"
        )[0]?.value,
        mileage: generalData.mileage?.toString(),
        spare_key_img: generalData.images.filter(
          (item) => item.key === "spare_key_img"
        ),
        booklet_img: generalData.images.filter(
          (item) => item.key === "booklet_img"
        ),
        mileage_img: generalData.images.filter(
          (item) => item.key === "mileage_img"
        ),
        grade: generalData.grade,
      });
    }
  }, [generalData, reset]);

  const hasSpareKey = watch("hasSpareKey");
  const hasBooklet = watch("hasBooklet");

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const formData = new FormData();

    selectedSpareKeyImage &&
      hasSpareKey === "true" &&
      formData.append("spare_key_img", selectedSpareKeyImage);
    selectedMilageImage &&
      hasMileageImg &&
      formData.append("mileage_img", selectedMilageImage);
    selectedBookletImage &&
      hasBooklet === "true" &&
      formData.append("booklet_img", selectedBookletImage);
    formData.append("reportId", reportId);
    formData.append("noOfOwners", data.noOfOwners);
    formData.append("hasBooklet", data.hasBooklet);
    formData.append("hasSpareKey", data.hasSpareKey);
    formData.append("mileage", data.mileage);
    formData.append("generalRemark", data.generalRemark);
    formData.append("sellRequestId", sellRequestId);
    formData.append("grade", data.grade);

    putData({
      url: `internal/inspection-reports/${reportId}/general`,
      payload: formData,
      formData: true,
    }).then((res: any) => {
      if ("error" in res && res.error && (res.error.data || res.error.error)) {
        dispatch(
          setToaster({
            isOpen: true,
            title:
              res.error?.data?.message ||
              res.error.error ||
              "Submission Failed",
            type: "error",
          })
        );
      } else {
        refetch();
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully car sell requested is submitted",
            type: "success",
          })
        );
      }
    });
  };

  const hasSpareKeyImg = watch("spare_key_img");
  const hasBookletImg = watch("booklet_img");
  const hasMileageImg = watch("mileage_img");

  if (!data) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: 10,
        }}
      >
        <CircularProgress size={100} />
      </Box>
    );
  }

  const onDeleteMileageImage = (mileageImage: any) => {
    if (mileageImage && mileageImage.length) {
      deleteImage({
        url: `/internal/sell-requests/operations/images/insepection-general/${mileageImage[0].id}`,
      }).then(() => {
        setSelectedMileageImage(null);
        refetch();
      });
    } else {
      setSelectedMileageImage(null);
    }
  };

  const onDeleteSpareKeyImage = (spareKeyImage: any) => {
    if (spareKeyImage && spareKeyImage.length) {
      deleteImage({
        url: `/internal/sell-requests/operations/images/insepection-general/${spareKeyImage[0].id}`,
      }).then(() => {
        setSelectedSpareKeyImage(null);
        refetch();
      });
    } else {
      setSelectedSpareKeyImage(null);
    }
  };

  const onDeleteBookletImage = (bookletImage: any) => {
    if (bookletImage && bookletImage.length) {
      deleteImage({
        url: `/internal/sell-requests/operations/images/insepection-general/${bookletImage[0].id}`,
      }).then(() => {
        setSelectedBookletImage(null);
        refetch();
      });
    } else {
      setSelectedBookletImage(null);
    }
  };

  const fileSelectedHandler = (e: any) => {
    onSelectMilageImage(e[0]);
  };

  return (
    <Container sx={{ mb: 12 }}>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ my: 4, width: 600, margin: "auto" }}
      >
        <Box
          display={"flex"}
          gap={10}
          width={"100%"}
          flexWrap={{ xs: "wrap", md: "nowrap" }}
          flexDirection={{ xs: "column-reverse", md: "row" }}
        >
          <Box flexGrow={1} width={"100%"}>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Box px={2}>
                <SelectFormHook
                  id="grade"
                  name="grade"
                  label="Grade"
                  control={control}
                  defaultValue={""}
                >
                  {[
                    { id: 1, title: "1" },
                    { id: 2, title: "2" },
                    { id: 3, title: "3" },
                    { id: 4, title: "4" },
                    { id: 5, title: "5" },
                  ].map((item: { id: number; title: string }) => {
                    return (
                      <MenuItem key={item.id} value={item.title}>
                        <Grade gradeNumber={item.id} />
                      </MenuItem>
                    );
                  })}
                </SelectFormHook>
              </Box>
              <Box px={2}>
                <TextField
                  {...register("noOfOwners")}
                  margin="normal"
                  fullWidth
                  id="noOfOwners"
                  label="Number of Pervious Owners"
                  autoComplete="noOfOwners"
                  InputLabelProps={{ shrink: true }}
                  
                  type="number"
                  error={!!errors?.noOfOwners?.message}
                  helperText={errors?.noOfOwners?.message}
                />
              </Box>
              <Box sx={{ display: "flex" }} gap={2} p={2}>
                <TextField
                  {...register("mileage")}
                  margin="normal"
                  fullWidth
                  id="mileage"
                  label="Mileage"
                  autoComplete="mileage"
                  InputLabelProps={{ shrink: true }}
                  
                  error={!!errors?.mileage?.message}
                  helperText={errors?.mileage?.message}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Dropzone onDrop={fileSelectedHandler}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <Box
                          {...getRootProps()}
                          sx={{
                            width: 200,
                            height: 140,

                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: "rgba(0,0,0,0.2)",
                            my: 2,
                          }}
                        >
                          <input
                            id="upload-image-mileage"
                            type="file"
                            multiple
                            style={{ display: "none", width: "100%" }}
                            accept=" image/png, image/gif, image/jpeg , image/jpg,image/heic"
                            {...getInputProps()}
                          />
                          <label htmlFor={`upload-image-mileage`}>
                            <Box display={"flex"} flexDirection={"column"}>
                              {(hasMileageImg && hasMileageImg.length) ||
                              selectedMilageImage ? (
                                <>
                                  <img
                                    src={
                                      selectedMilageImage
                                        ? URL.createObjectURL(
                                            selectedMilageImage
                                          )
                                        : hasMileageImg[0]?.url
                                    }
                                    width={200}
                                    height={200}
                                    style={{ objectFit: "contain" }}
                                  />
                                </>
                              ) : (
                                <AddPhotoAlternate
                                  style={{ cursor: "copy" }}
                                  fontSize="large"
                                  sx={{
                                    width: "60px",
                                    height: "60px",
                                    color: "gray",
                                  }}
                                />
                              )}
                            </Box>
                          </label>
                        </Box>
                      </section>
                    )}
                  </Dropzone>
                  {(hasMileageImg && hasMileageImg.length) ||
                  selectedMilageImage ? (
                    <Box>
                      <IconButton
                        onClick={() => onDeleteMileageImage(hasMileageImg)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ) : null}
                </Box>
              </Box>

              <Box
                p={2}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",

                  gap: 2,
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",

                    gap: 2,
                  }}
                >
                  <SelectFormHook
                    id="hasSpareKey"
                    name="hasSpareKey"
                    label="Spare Key"
                    control={control}
                    defaultValue={""}
                  >
                    {[
                      { id: 1, title: "true" },
                      { id: 2, title: "false" },
                    ].map((item: { id: number; title: string }) => {
                      return (
                        <MenuItem key={item.id} value={item.title}>
                          {item.title === "true" ? "YES" : "NO"}
                        </MenuItem>
                      );
                    })}
                  </SelectFormHook>

                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {hasSpareKey === "true" ? (
                      <Dropzone onDrop={(e) => onSelectSpareKeyImage(e[0])}>
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <Box
                              {...getRootProps()}
                              sx={{
                                width: 200,
                                height: 140,

                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "rgba(0,0,0,0.2)",
                                my: 2,
                              }}
                            >
                              <input
                                id="upload-image-spare_key"
                                type="file"
                                multiple
                                style={{ display: "none", width: "100%" }}
                                accept=" image/png, image/gif, image/jpeg , image/jpg,image/heic"
                                {...getInputProps()}
                              />

                              <label htmlFor={`upload-image-spare_key`}>
                                <Box display={"flex"} flexDirection={"column"}>
                                  {hasSpareKey === "true" && (
                                    <>
                                      {(hasSpareKeyImg &&
                                        hasSpareKeyImg.length) ||
                                      selectedSpareKeyImage ? (
                                        <>
                                          {" "}
                                          <img
                                            src={
                                              selectedSpareKeyImage
                                                ? URL.createObjectURL(
                                                    selectedSpareKeyImage
                                                  )
                                                : hasSpareKeyImg[0]?.url
                                            }
                                            width={200}
                                            height={200}
                                            style={{ objectFit: "contain" }}
                                          />
                                        </>
                                      ) : (
                                        <AddPhotoAlternate
                                          style={{ cursor: "copy" }}
                                          fontSize="large"
                                          sx={{
                                            width: "60px",
                                            height: "60px",
                                            color: "gray",
                                          }}
                                        />
                                      )}
                                    </>
                                  )}
                                </Box>
                              </label>
                            </Box>
                          </section>
                        )}
                      </Dropzone>
                    ) : null}

                    {(hasSpareKeyImg && hasSpareKeyImg.length) ||
                    selectedSpareKeyImage ? (
                      <Box>
                        <IconButton
                          onClick={(e) => {
                            e.preventDefault();
                            onDeleteSpareKeyImage(hasSpareKeyImg);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    ) : null}
                  </Box>
                </Box>
              </Box>
              <Box
                p={2}
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: 2,
                }}
              >
                <SelectFormHook
                  id="hasBooklet"
                  name="hasBooklet"
                  label="Booklet"
                  control={control}
                  defaultValue={""}
                >
                  {[
                    { id: 1, title: "true" },
                    { id: 2, title: "false" },
                  ].map((item: { id: number; title: string }) => {
                    return (
                      <MenuItem key={item.id} value={item.title}>
                        {item.title === "true" ? "YES" : "NO"}
                      </MenuItem>
                    );
                  })}
                </SelectFormHook>

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  {hasBooklet === "true" ? (
                    <Dropzone onDrop={(e) => onSelectBookletImage(e[0])}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <Box
                            {...getRootProps()}
                            sx={{
                              width: 200,
                              height: 140,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: "rgba(0,0,0,0.2)",
                              my: 2,
                            }}
                          >
                            <input
                              id="upload-image-booklet"
                              type="file"
                              multiple
                              style={{ display: "none", width: "100%" }}
                              accept=" image/png, image/gif, image/jpeg , image/jpg,image/heic"
                              {...getInputProps()}
                            />

                            <label htmlFor={`upload-image-booklet`}>
                              <Box display={"flex"} flexDirection={"column"}>
                                {hasBooklet === "true" && (
                                  <>
                                    {(hasBookletImg && hasBookletImg.length) ||
                                    selectedBookletImage ? (
                                      <>
                                        {" "}
                                        <img
                                          src={
                                            selectedBookletImage
                                              ? URL.createObjectURL(
                                                  selectedBookletImage
                                                )
                                              : hasBookletImg[0]?.url
                                          }
                                          width={200}
                                          height={200}
                                          style={{ objectFit: "contain" }}
                                        />
                                      </>
                                    ) : (
                                      <AddPhotoAlternate
                                        style={{ cursor: "copy" }}
                                        fontSize="large"
                                        sx={{
                                          width: "60px",
                                          height: "60px",
                                          color: "gray",
                                        }}
                                      />
                                    )}
                                  </>
                                )}
                              </Box>
                            </label>
                          </Box>
                        </section>
                      )}
                    </Dropzone>
                  ) : null}

                  {(hasBookletImg && hasBookletImg.length) ||
                  selectedBookletImage ? (
                    <Box>
                      <IconButton
                        onClick={(e) => {
                          onDeleteBookletImage(hasBookletImg);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  ) : null}
                </Box>
              </Box>

              <TextField
                margin="normal"
                fullWidth
                label="Remark"
                {...register("generalRemark")}
                autoComplete="generalRemark"
                error={!!errors?.generalRemark?.message}
                helperText={errors?.generalRemark?.message}
                sx={{ m: 0 }}
                multiline
                rows={4}
                InputLabelProps={{ shrink: true }}
              />
              <LoadingButton
                loading={result.isLoading || deleteResult.isLoading}
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
