import { FormControl, InputLabel, Select, Typography, SelectChangeEvent } from "@mui/material";
import { FC, ReactNode } from "react";
import { Control, Controller } from "react-hook-form";

interface Props {
  name: string;
  id: string;
  label: string;
  control: Control<any, any>;
  defaultValue: any;
  children: ReactNode;
  disabled?: boolean;
  required?: boolean;
  helperText?: string | ReactNode;
  readOnly?: boolean;
  showHelperText?: boolean;
}

export const SelectFormHook: FC<Props> = ({
  name,
  label,
  control,
  id,
  defaultValue,
  children,
  disabled,
  required,
  helperText,
  readOnly,
  showHelperText,
  ...props
}) => {
  const labelId = `${id}-label`;

  return (
    <FormControl fullWidth required={required} disabled={disabled} {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { onChange, value } }) => (
          <Select
            id={id}
            labelId={labelId}
            value={value}
            onChange={onChange as (event: SelectChangeEvent<any>) => void}
            label={label}
            readOnly={readOnly}
          >
            {children}
          </Select>
        )}
      />
      {showHelperText && helperText && (
        <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5 }}>
          {helperText}
        </Typography>
      )}
    </FormControl>
  );
};
