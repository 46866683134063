import { Card } from "../../shared/card";
import "./styles.scss";

import { usePatchRequestMutation } from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import moment from "moment";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import { BuyRequestCarT } from "../../../pages/buy-request-details/types";

import React from "react";

const statusData = [
  "fee-paid",
  "pending",
  "contacted",
  "loan-approved",
  "loan-rejected",
];
interface Inputs {
  status: string;
}

interface IProps {
  buyData: BuyRequestCarT;
  id?: string;
}

export function BuyerInfo(props: IProps) {
  const { buyData, id } = props;
  const dispatch = useDispatch();
  const [patchData, result] = usePatchRequestMutation();
  const [status, setStatus] = React.useState(buyData.status);
  const [remark, setRemark] = React.useState(buyData.remark);
  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const onStatusHandler = () => {
    patchData({
      url: `${REST_STAFF_ENDPOINTS.buyRequest}/${id}/status`,
      payload: { status: status },
    }).then((res: any) => {
      if ("error" in res && res.error && res.error.data) {
        dispatch(
          setToaster({
            isOpen: true,
            title: res.error?.data?.message || "Submission Failed",
            type: "error",
          })
        );
      } else {
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully is updated",
            type: "success",
          })
        );
      }
    });
  };

  return (
    <div className="car-detail-container">
      <Card header="Buyer Info">
        <Box
          sx={{
            margin: "auto",
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >

          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="name"
              label="Name"
              autoComplete="name"
              autoFocus
              value={buyData.buyer.name}
            />
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="c"
              label="Email"
              autoComplete="email"
              autoFocus
              value={buyData.buyer.email}
            />
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              autoComplete="phoneNumber"
              autoFocus
              value={buyData.buyer.phone}
            />
          </div>
        </Box>
      </Card>
    </div>
  );
}
