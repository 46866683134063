import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import {
    Box,
    TextField,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Pagination,
    Select,
    MenuItem,
    FormHelperText,
    InputLabel,
    FormControl,
    LinearProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Card } from "../../shared/card";
import {
    useGetQueryQuery,
    usePostRequestMutation,
    useDeleteRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";

interface IProps {
    requestId: number;
    refetchParent?: () => void;
}

interface Inputs {
    tag: string;
    link: string;
}

interface YoutubeLink {
    id: number;
    tag: string;
    link: string;
}

const itemsPerPage = 5;

const mapTagToName = (tag: string) => {
    switch (tag) {
        case "test_drive":
            return "Test Drive";
        case "review":
            return "Review";
        default:
            return tag;
    }
};

export default function YoutubeLinks({ requestId, refetchParent }: IProps) {
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<Inputs>();

    const dispatch = useDispatch();
    const [postLink] = usePostRequestMutation();
    const [deleteLink] = useDeleteRequestMutation();
    const [linkList, setLinkList] = useState<YoutubeLink[]>([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const { data: linkData, refetch } = useGetQueryQuery(
        `${REST_STAFF_ENDPOINTS.publicSellRequest}/${requestId}/links`,
        { skip: !requestId }
    );

    useEffect(() => {
        if (linkData) {
            setLinkList(Array.isArray(linkData?.links) ? linkData.links : []);
        }
    }, [linkData]);

    const handleAdd = (data: Inputs) => {
        const payload = {
            tag: data.tag,
            link: data.link,
        };

        setLoading(true);

        postLink({
            url: `${REST_STAFF_ENDPOINTS.sellRequest}/${requestId}/link`,
            payload,
        }).then((res: any) => {
            setLoading(false);
            if (res.error) {
                dispatch(
                    setToaster({
                        isOpen: true,
                        title: res.error?.data?.message || "Failed to add link",
                        type: "error",
                    })
                );
            } else {
                dispatch(
                    setToaster({
                        isOpen: true,
                        title: "Link added successfully",
                        type: "success",
                    })
                );
                reset();
                refetch();
                refetchParent?.();
            }
        });
    };

    const handleDelete = (linkId: number) => {
        setLoading(true);
        deleteLink({
            url: `/internal/sell-requests/link/${linkId}`,
        }).then((res: any) => {
            setLoading(false);
            if (res.error) {
                dispatch(
                    setToaster({
                        isOpen: true,
                        title: res.error?.data?.message || "Failed to delete link",
                        type: "error",
                    })
                );
            } else {
                dispatch(
                    setToaster({
                        isOpen: true,
                        title: "Link deleted successfully",
                        type: "success",
                    })
                );
                refetch();
                refetchParent?.();
            }
        });
    };

    const handlePageChange = (_: any, newPage: number) => setPage(newPage);

    const paginatedData = linkList.slice(
        (page - 1) * itemsPerPage,
        page * itemsPerPage
    );

    return (
        <div className="car-detail-container">
            <Card header="Youtube Links">
                <Box component="form" onSubmit={handleSubmit(handleAdd)} sx={{ mb: 2 }}>
                    <div className="row">
                        <div className="col-sm-4 col-md-3">
                            <FormControl fullWidth size="small" error={!!errors.tag}>
                                <InputLabel id="type-label">Type</InputLabel>
                                <Select
                                    {...register("tag", { required: "Type is required" })}
                                    labelId="type-label"
                                    label="Type"
                                    defaultValue=""
                                >
                                    <MenuItem value="review">Review</MenuItem>
                                    <MenuItem value="test_drive">Test Drive</MenuItem>
                                </Select>
                                {errors.tag && (
                                    <FormHelperText error>{errors.tag?.message}</FormHelperText>
                                )}
                            </FormControl>
                        </div>
                        <div className="col-sm-6 col-md-6">
                            <TextField
                                {...register("link", { required: "Link is required" })}
                                label="Youtube Link"
                                fullWidth
                                size="small"
                                error={!!errors.link}
                                helperText={errors.link?.message}
                            />
                        </div>
                        <div className="col-sm-2 col-md-2 d-flex align-items-center">
                            <LoadingButton type="submit" variant="contained" size="medium">
                                Add
                            </LoadingButton>
                        </div>
                    </div>
                </Box>

                {loading && <LinearProgress sx={{ mb: 2 }} />}

                <TableContainer component={Paper} sx={{ mb: 2 }}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell><strong>ID</strong></TableCell>
                                <TableCell><strong>Type</strong></TableCell>
                                <TableCell><strong>Link</strong></TableCell>
                                <TableCell><strong>Action</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.length ? (
                                paginatedData.map((row, index) => (
                                    <TableRow key={row.id}>
                                        <TableCell>{(page - 1) * itemsPerPage + index + 1}</TableCell>
                                        <TableCell>{mapTagToName(row.tag)}</TableCell>
                                        <TableCell>{row.link}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleDelete(row.id)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <Typography textAlign="center" fontStyle="italic" color="text.secondary">
                                            No records found.
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                {linkList.length > itemsPerPage && (
                    <Box display="flex" justifyContent="center">
                        <Pagination
                            count={Math.ceil(linkList.length / itemsPerPage)}
                            page={page}
                            onChange={handlePageChange}
                            color="primary"
                        />
                    </Box>
                )}
            </Card>
        </div>
    );
}
