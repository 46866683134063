import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBuyRequestApi } from "./actions/buy-requests";
import { fetchBasicItems } from "./actions/basic-items";
import { IBasicItemsResponse } from "../../../../core/types/basic-items";
import {
  IBuyRequest,
} from "../../../../core/types/buy-request";

type InitialState = {
  list: IBuyRequest[];
  count?: number;
  basicItems?: IBasicItemsResponse;
  loading: boolean;
  isBuyRequestModalOpen: boolean;
  queryParams?: {
    id?: any;
    status?: string;
    brand?: string;
    model?: string;
    registration_no?: string;
    start_date?: string;
    end_date?: string;
    buyer_name?: string;
    tracking_code?: string;
    management?: string;
    buyer_phone?: string;
    tag?: string;
    buyer_email?: string;
    grade?: string;
    Models?: any;
    state_id?: any;
  };
};

const initialState: InitialState = {
  list: [],
  loading: false,
  isBuyRequestModalOpen: false,
};

export const auth = createSlice({
  name: "buy-requests",
  initialState,
  reducers: {
    setIsBuyRequestModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isBuyRequestModalOpen = action.payload;
    },
    setQueryParams: (state, action: PayloadAction<Object>) => {
      state.queryParams = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchBuyRequestApi.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(fetchBuyRequestApi.fulfilled, (state, action) => {
      state.list = action.payload?.list;
      state.count = action.payload?.count;
      state.loading = false;
    });
    builder.addCase(fetchBasicItems.fulfilled, (state, action) => {
      state.basicItems = action.payload;
    });
  },
});

export const { setIsBuyRequestModalOpen, setQueryParams } = auth.actions;

export default auth.reducer;
