import React, { useEffect, useState } from "react";
import "./styles.scss";

import {
  useAppDispatch,
  useAppSelector,
} from "../../../utility/redux/state/hooks";
import { Button } from "../../components/shared/button";
import moment from "moment";
import Loading from "../../components/General/loading/loading";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { PageHeader } from "../../components/shared/page-header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BuyRequestFilter } from "../../components/buy-request/buy-requests-filter";
import { Toaster } from "../../components/General/Toaster";
import { Box, Typography } from "@mui/material";
import { REST_STAFF_ENDPOINTS } from "../../../utility/redux/api/endpoints";
import { useGetQueryQuery } from "../../../utility/redux/api/api-slice";
import { BuyRequestT } from "./types";
import { cleanObject } from "../../../utility/helpers/objectutily";
import { setQueryParams } from "../../../utility/redux/state/features/buy-requests.slicer";
import { fetchBuyRequestApi } from "../../../utility/redux/state/features/actions/buy-requests";

interface filterType {
  model: string;
  brand: string;
  end_date: string;
  start_date: string;
  status: string;
  tracking_code: string;
}

const PAGE_SIZE = 10;

export const BuyRequestList: React.FC = () => {
  const buyRequest = useAppSelector((state) => state.buyRequestReducer);
  console.log("buyRequest", buyRequest)
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPageParams = searchParams?.get("page")
    ? Number(searchParams.get("page"))
    : 0;
  const toaster = useAppSelector((state) => state.authReducer.toaster);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [currentPage, setPage] = useState(currentPageParams);

  const [filterParams, setFilterParams] = useState<any>([]);

  let url = `${REST_STAFF_ENDPOINTS.buyRequest}`;

  if (filterParams.length) {
    const searchParams = filterParams.join("&");
    url = `${REST_STAFF_ENDPOINTS.buyRequest}?${searchParams}`;
  }

  if (currentPage) {
    url = `${REST_STAFF_ENDPOINTS.buyRequest}?skip=${currentPage * PAGE_SIZE
      }&take=${PAGE_SIZE}`;
  }

  // const { data: listData } = useGetQueryQuery(url);
  // const buyRequestListData: BuyRequestT = listData;

  // useEffect(() => {
  //   setSearchParams(
  //     `?${new URLSearchParams({ page: currentPage.toString() })}`
  //   );
  // }, [currentPage]);

  useEffect(() => {
    setSearchParams(
      `?${new URLSearchParams({
        ...buyRequest.queryParams,
        page: currentPage.toString(),
      })}`
    );
  
    dispatch(
      fetchBuyRequestApi({
        skip: currentPage * PAGE_SIZE,
        take: PAGE_SIZE,
        ...buyRequest.queryParams,
      })
    );
  }, [currentPage, buyRequest.queryParams]);
  

  const handleFilterRequest = (filter: filterType) => {
    const params = [];
    const filterKeys = Object.keys(filter);

    for (const key of filterKeys) {
      //@ts-ignore
      if (filter[key] as any) {
        //@ts-ignore
        params.push(`${key}=${filter[key]}`);
      }
    }
    setFilterParams(params);
  };

  useEffect(() => {
      const currentPageParams: number = searchParams.get("page")
        ? Number(searchParams.get("page"))
        : 0;
  
      const status = searchParams.get("status");
      const brand = searchParams.get("brand");
      const model = searchParams.get("model");
      const registration_no = searchParams.get("registration_no");
      const start_date = searchParams.get("start_date");
      const end_date = searchParams.get("end_date");
      const client = searchParams.get("client");
      const tracking_code = searchParams.get("tracking_code");
  
      const queryParamsFilter = {
        status,
        brand,
        model,
        registration_no,
        start_date,
        end_date,
        client,
        tracking_code,
      };
  
      const currentQueyFilter = cleanObject(queryParamsFilter);
  
      dispatch(setQueryParams(currentQueyFilter));
      dispatch(
        fetchBuyRequestApi({
          skip: currentPageParams * PAGE_SIZE,
          take: PAGE_SIZE,
          ...currentQueyFilter,
        })
      );
    }, []);

  if (!buyRequest?.list) {
    return <Loading />;
  }

  return (
    <Box sx={{ mb: 4 }}>
      <Toaster
        isOpen={toaster.isOpen}
        title={toaster.title}
        description={toaster.description}
        type={toaster.type ?? "success"}
      />
      <PageHeader label="Buy Requests" />

      <div className="">
        <div className="filter-panel">
          <BuyRequestFilter
            buyReq
            countBuyReq={buyRequest.list?.length}
            onChangeFilter={(newFilter) => {
              // setPage(0);
              // handleFilterRequest(newFilter);
              const currentQueyFilter = cleanObject(newFilter);
              dispatch(setQueryParams(currentQueyFilter));
              setPage(0);
              dispatch(
                fetchBuyRequestApi({
                  ...currentQueyFilter,
                  skip: currentPage * PAGE_SIZE,
                  take: PAGE_SIZE,
                })
              );


            }}
          />
        </div>
        <Box sx={{ p: 2 }}>
          <table className="table table-sm table-hover">
            <thead className="bg-dark">
              <tr>
                <th scope="col">#</th>
                <th scope="col">Booking ID</th>
                <th scope="col">Make</th>
                <th scope="col">Price</th>
                <th scope="col">Seller Info</th>
                <th scope="col">Buyer Info</th>
                <th scope="col">Registration No</th>
                <th scope="col">Created Date</th>
                <th scope="col">Last Update</th>
                <th scope="col">Status</th>
                <th scope="col">Tracking Code</th>
                <th scope="col">Image</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody style={{ verticalAlign: "middle" }}>
              {buyRequest?.list?.map((req, index) => (
                <tr key={req.id}>
                  <th scope="row">{index + 1 + currentPage * PAGE_SIZE}</th>

                  <td style={{ width: "100px" }}>
                    <Typography variant="caption" sx={{ width: 100 }}>
                      {req.id}
                    </Typography>
                  </td>
                  <td style={{ width: "100px" }}>
                    <Typography variant="caption" sx={{ width: 100 }}>
                      {req.car.make.brand} {req.car.make.model}{" "}
                      {req.car.make.variant}
                    </Typography>
                  </td>

                  <td>
                    {req.car.finalized_price.amount
                      ? (+req.car.finalized_price.amount).toFixed(2)
                      : 0.0}{" "}
                  </td>

                  <td style={{ width: "100px" }}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography variant="caption">
                        {" "}
                        {req.seller.name}
                      </Typography>
                      <Typography variant="caption">
                        {req.seller.email}
                      </Typography>
                      <Typography variant="caption">
                        {" "}
                        {req.seller.phone}
                      </Typography>
                    </Box>{" "}
                  </td>

                  <td style={{ width: "100px" }}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Typography variant="caption">
                        {" "}
                        {req.buyer.name}
                      </Typography>
                      <Typography variant="caption">
                        {req.buyer.phone}
                      </Typography>
                      <Typography variant="caption">
                        {" "}
                        {req.buyer.email}
                      </Typography>
                    </Box>{" "}
                  </td>

                  <td>
                    {" "}
                    <Typography variant="caption" sx={{ width: 100 }}>
                      {req.car.registrationNo}
                    </Typography>{" "}
                  </td>
                  <td> {moment(req.createdAt).fromNow()} </td>
                  <td>{moment(req.updatedAt).fromNow()} </td>
                  <td>
                    <span>{req.status}</span>
                  </td>
                  <td>
                    <span>{req.trackingCode}</span>
                  </td>
                  <td>
                    <img
                      style={{ maxHeight: "120px" }}
                      className="img img-thumbnail"
                      src={req.car.selectedImage.thumbnail}
                    />
                  </td>
                  <td>
                    {" "}
                    <Button
                      type="button"
                      onClick={() => {
                        navigate(`/buy-request/${req.id}`);
                      }}
                      label="Details"
                      variant="outlined"
                    />{" "}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
      </div>

      <Box sx={{ display: "flex", justifyContent: "space-around", px: 2 }}>
        <Button
          label="PREV"
          variant="outlined"
          disabled={currentPage == 0}
          icon={<FaChevronLeft />}
          onClick={() => {
            if (currentPage == 0) return;
            setPage(currentPage - 1);
          }}
        />
        <Box component={"span"} sx={{ alignItems: "center", p: 2 }}>
          {" Page "}
          {currentPage + 1} of{" "}
          {buyRequest.count
            ? Math.ceil(buyRequest.count / PAGE_SIZE)
            : "NA"}{" "}
        </Box>

        <Button
          label="NEXT"
          variant="outlined"
          icon={<FaChevronRight />}
          iconPosition="right"
          disabled={(currentPage + 1) * PAGE_SIZE >= (buyRequest.count ?? 0)}
          onClick={() => setPage(currentPage + 1)}
        />
      </Box>
    </Box>
  );
};
