import { Card } from "../../shared/card";
import "./styles.scss";

import {
  useGetQueryQuery,
  usePatchRequestMutation,
} from "../../../../utility/redux/api/api-slice";
import { REST_STAFF_ENDPOINTS } from "../../../../utility/redux/api/endpoints";
import moment from "moment";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { useDispatch } from "react-redux";
import { setToaster } from "../../../../utility/redux/state/features/auth.slicer";
import {
  BuyRequestCarT,
  Promotion,
} from "../../../pages/buy-request-details/types";

import React from "react";
import { useAppSelector } from "../../../../utility/redux/state/hooks";
import { statusesT } from "./types";

const statusData = [
  "fee-paid",
  "pending",
  "contacted",
  "loan-approved",
  "loan-rejected",
];
interface Inputs {
  status: string;
}

interface IProps {
  buyData: BuyRequestCarT;
  id?: string;
  promotion?: Promotion;
}

export function GeneralDetailsBuyRequest(props: IProps) {
  const { buyData, id, promotion } = props;
  const dispatch = useDispatch();

  const { data: statuses } = useGetQueryQuery(
    `${REST_STAFF_ENDPOINTS.basics}/buy-request/statuses`
  );

  const statusData: statusesT = statuses;
  const [patchData, result] = usePatchRequestMutation();
  const [status, setStatus] = React.useState(buyData.status);
  const [remark, setRemark] = React.useState(buyData.remark);
  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const onStatusHandler = () => {
    patchData({
      url: `${REST_STAFF_ENDPOINTS.buyRequest}/${id}/status`,
      payload: { status: status },
    }).then((res: any) => {
      if ("error" in res && res.error && res.error.data) {
        dispatch(
          setToaster({
            isOpen: true,
            title: res.error?.data?.message || "Submission Failed",
            type: "error",
          })
        );
      } else {
        dispatch(
          setToaster({
            isOpen: true,
            title: "Successfully is updated",
            type: "success",
          })
        );
      }
    });
  };

  return (
    <div className="car-detail-container">
      <Card header="General">
        <Box
          sx={{
            margin: "auto",
            display: "flex",
            alignItems: "center",

            gap: 2,
          }}
        >
          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="CreationTime"
              label="Creation Time"
              autoComplete="CreationTime"
              
              value={
                moment(buyData.createdAt).format("hh:mm A DD MMM YYYY") || ""
              }
            />
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="trackingCode"
              label="Tracking Code"
              autoComplete="trackingCode"
              
              value={buyData.trackingCode}
            />
          </div>

          <div className="col-sm-6 col-md-3 col-lg-2">
            <TextField
              InputProps={{
                readOnly: true,
              }}
              margin="normal"
              fullWidth
              id="promotionCode"
              label="Promotion Code"
              autoComplete="promotionCode"
              
              value={promotion?.key}
            />
          </div>

          <Box className="col-sm-6 col-md-3 col-lg-6" display={"flex"} gap={1}>
            <FormControl>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                sx={{ width: 260 }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Age"
                onChange={handleChange}
              >
                {statusData?.statuses.map((st) => (
                  <MenuItem value={st}>{st.toUpperCase()}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={onStatusHandler}
              sx={{ width: 60 }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Card>
    </div>
  );
}
