import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../../ui/pages/login/login";
import Dashboard from "../../ui/pages/dashboard/dashboard";
import SellRequestDetails from "../../ui/pages/sell-request-details/sell-request-detail";
import Layout from "../../ui/components/layout/layout";
import { InspectionReport } from "../../ui/pages/inspection-report/inspection-report";
import { SellRequestList } from "../../ui/pages/sell-requests/list";
import { BuyRequestList } from "../../ui/pages/buy-requests/list";
import { BuyRequestDetails } from "../../ui/pages/buy-request-details";
import { Report } from "../../ui/pages/report";
import { MetaData } from "../../ui/pages/metaData/metaData";
const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={showWithLayout(<Dashboard />)} />
        <Route
          path="sell-requests"
          element={showWithLayout(<SellRequestList />)}
        />
        <Route
          path="buy-requests"
          element={showWithLayout(<BuyRequestList />)}
        />
        <Route
          path="inspection-report/:id"
          element={showWithLayout(<InspectionReport />)}
        />
        <Route
          path="buy-request/:id"
          element={showWithLayout(<BuyRequestDetails />)}
        />
        <Route
          path="sell-requests/:id"
          element={showWithLayout(<SellRequestDetails />)}
        />
        <Route path="report" element={showWithLayout(<Report />)} />
        <Route path="metadata" element={showWithLayout(<MetaData />)} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

function showWithLayout(component: any) {
  return <Layout> {component} </Layout>;
}
